import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'my-staff',
    templateUrl: 'staff.component.html'
})

export class StaffComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
