import { NgModule } from '@angular/core';
import { UtilsService } from './utils.service';


@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        UtilsService
    ],
})
export class UtilsModule { }
